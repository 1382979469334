:root {
  --pink: rgb(255, 93, 98);
  --pink-lighter: rgb(243, 185, 184);
  --blue: rgb(0, 15, 159);
  --blue-darker: rgb(0, 38, 120);
  --grey: rgb(239, 239, 240);
}

@font-face {
  font-family: 'ploni';
  font-weight: 400;
  font-style: normal;
  src: url('./fonts/ploni-regular-aaa.eot') format('eot'), url('./fonts/ploni-regular-aaa.woff') format('woff'),
    url('./fonts/ploni-regular-aaa.woff2') format('woff2');
}

body {
  direction: rtl;
  margin: 0;
  font-family: ploni, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
    'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
